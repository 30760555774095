import type { MaybeRef } from '@vueuse/core'
import { useHead } from '@vueuse/head'
import { computed, unref } from 'vue'
import { useI18n } from 'vue-i18n'

export function usePageTitle(pageTitle: MaybeRef<string | undefined | null>) {
  const { t } = useI18n()
  useHead({
    title: computed(() => {
      if (!unref(pageTitle))
        return t('pageTitle.template')
      return `${unref(pageTitle)} › ${t('pageTitle.template')}`
    },
    ),
  })
}
