import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createHead } from '@vueuse/head'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import { PiniaDebounce } from '@pinia/plugin-debounce'
import { useDebounceFn } from '@vueuse/core'
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import * as Sentry from '@sentry/vue'

import i18n from './i18n'
import router from './router'
import App from './App.vue'

import '@unocss/reset/tailwind-compat.css'
import 'virtual:uno.css'
import './assets/scss/main.scss'

const pinia = createPinia()
const head = createHead()
pinia.use(piniaPluginPersistedstate)
pinia.use(PiniaDebounce(useDebounceFn))
const app = createApp({ ...App, name: 'Robojob Customer Portal' })

Sentry.init({
  app,
  dsn: 'https://007aa90a5cd34371a8e7f94c61c55681@sentry.appwi.se/334',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

app
  .use(pinia)
  .use(i18n)
  .use(router)
  .use(head)
  .use(autoAnimatePlugin)
  .mount('#app')
