import axios from 'axios'

const axiosClient = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  },
  // timeout: 2000,
})

axiosClient.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (error.request.readyState === 4) {
      let message = ''
      const parsed_response = JSON.parse(error.request.response)
      if (parsed_response.errors) {
        message
                    = parsed_response.errors[
            Object.keys(parsed_response.errors)[0]
          ][0]
      }
      else {
        message = parsed_response.message
      }
      window.$message.error(message, {
        duration: 5000,
        closable: true,
        keepAliveOnHover: true,
      })
    }
    return Promise.reject(error)
  },
)

export function setToken(token, token_type) {
  axiosClient.defaults.headers.common.authorization = `${token_type} ${token}`
}

export function clearToken() {
  delete axiosClient.defaults.headers.common.authorization
}
export function setLanguage(language) {
  axiosClient.defaults.headers.common['Accept-Language'] = language
}

export default axiosClient
