import { defineStore } from 'pinia'

export const useAppSettingsStore = defineStore('appSettings', {
  state: () => {
    return {
      sideNavCollapsed: false,
      redirectUrl: null,
      responsiveWarning: true,
      appLanguage: undefined,
      onlyShowMyOrders: false,
      logBookCollapsed: false,
    }
  },
  persist: true,
})
