import { defineStore } from 'pinia'
import http, { clearToken, setToken } from '../http'
import router from '../router'
import { useAppSettingsStore } from './appSettings'
import { useUsersStore } from './users'

// TODO: use pinia persistant plugin for storage of tokens to localstorage

// useStore could be anything like useUsemainr, useCart
// the first argument is a unique id of the store across your application
export const useAuthStore = defineStore('auth', {
  // other options...
  state: () => {
    const token = localStorage.getItem('token')
    const token_type = localStorage.getItem('token_type')

    if (token && token_type)
      setToken(token, token_type)

    return {
      user: undefined,
      // TODO Temporary user routing

      userLoggedIn: false,
      token,
      token_type,
    }
  },
  actions: {
    login(username, password) {
      return http
        .post('/api/login', {
          email: username,
          password,
        })
        .then((response) => {
          this.processToken(response.data.token, response.data.token_type)
        })
    },

    processToken(token, token_type) {
      localStorage.setItem('token', token)
      localStorage.setItem(
        'token_type',
        token_type,
      )
      this.$patch({
        token,
        token_type,
        user: undefined,
      })
      setToken(this.token, this.token_type)
    },

    me() {
      return http.get('/api/me').then((response) => {
        this.user = response.data
      })
    },

    clearTokens() {
      localStorage.removeItem('token')
      localStorage.removeItem('token_type')
      this.$patch({
        token: undefined,
        token_type: undefined,
      })
      clearToken()
    },
    logout() {
      this.clearTokens()
      this.user = undefined
      router.push({ name: 'login' })
    },
    navigateToApp() {
      const appSettings = useAppSettingsStore()
      if (appSettings.redirectUrl) {
        const path = appSettings.redirectUrl

        if (router.resolve({ path }).name === 'error-404') {
          appSettings.redirectUrl = null
          router.push({ name: 'home' })
        }

        else {
          router.push({ path: appSettings.redirectUrl }).then(() => {
            appSettings.redirectUrl = null
          })
        }
      }

      else { router.push({ name: 'base' }) }
    },
    setCurrentUserLanguage(locale) {
      if (!this.user)
        return
      const formValue = {
        first_name: this.user.first_name ?? null,
        last_name: this.user.last_name ?? null,
        email: this.user.email ?? null,
        phone_number: this.user.phone_number ?? null,
        gender: this.user.gender ?? null,
        language: locale,
        starting_place: this.user.starting_place ?? null,
        profile_picture: this.user.profile_picture?.path ?? null,
        roles: this.user.roles.map(item => item.value) ?? null,
      }
      const usersStore = useUsersStore()
      usersStore.update(this.user.id, formValue).then(() => {
        this.me()
      })
    },
  },
  getters: {
    hasTokens: (state) => {
      if (state.token?.length && state.token_type?.length)
        return true

      else
        return false
    },
    isAdmin: (state) => {
      return state.user?.roles?.some(item => item.value === 'super admin' || item.value === 'admin')
    },
    fullName: (state) => {
      if (state.user)
        return `${state.user.first_name} ${state.user.last_name}`
    },
  },
})
