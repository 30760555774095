import { defineStore } from 'pinia'

// import _ from 'lodash'
import http from '../http'

// import { isUuid } from '../composables/testUuid'
export const useUsersStore = defineStore('users', {
  state: () => {
    return {
      users: [],
      user: [],
      search: '',
      userLoading: false,
      activeUsers: {
        users: [],
        pagination: {
          current_page: 1,
        },
        loading: false,
      },
      pendingUsers: {
        users: [],
        pagination: {
          current_page: 1,
        },
        loading: false,
      },
      blockedUsers: {
        users: [],
        pagination: {
          current_page: 1,
        },
        loading: false,
      },
      skills: [],
      skillsLoading: false,

      startingPlaces: [],
      startingPlacesLoading: false,

    }
  },
  actions: {
    getActiveUsers(filterParams) {
      this.activeUsers.loading = true
      let order = ''
      const urlSearchString = new URLSearchParams()
      if (this.search)
        urlSearchString.append('search', this.search)

      if (filterParams && filterParams.order) {
        if (filterParams.order === 'ascend')
          order = 'asc'

        else
          order = 'desc'

        urlSearchString.append(`sorts[${filterParams.columnKey}]`, order)
      }
      urlSearchString.append('page', this.activeUsers.pagination.current_page)
      return http.get(`/api/users?${urlSearchString.toString()}`)
        .then((response) => {
          this.activeUsers.users = response.data.data
          this.activeUsers.pagination = response.data.pagination
        }).finally(() => {
          this.activeUsers.loading = false
        })
    },
    getPendingUsers(filterParams) {
      this.pendingUsers.loading = true
      let order = ''
      const urlSearchString = new URLSearchParams()
      if (this.search)
        urlSearchString.append('search', this.search)

      if (filterParams && filterParams.order) {
        if (filterParams.order === 'ascend')
          order = 'asc'

        else
          order = 'desc'

        urlSearchString.append(`sorts[${filterParams.columnKey}]`, order)
      }
      urlSearchString.append('page', this.pendingUsers.pagination.current_page)
      return http.get(`/api/users/pending?${urlSearchString.toString()}`)
        .then((response) => {
          this.pendingUsers.users = response.data.data
          this.pendingUsers.pagination = response.data.pagination
        }).finally(() => {
          this.pendingUsers.loading = false
        })
    },
    getBlockedUsers(filterParams) {
      this.pendingUsers.loading = true
      let order = ''
      const urlSearchString = new URLSearchParams()
      if (this.search)
        urlSearchString.append('search', this.search)

      if (filterParams && filterParams.order) {
        if (filterParams.order === 'ascend')
          order = 'asc'

        else
          order = 'desc'

        urlSearchString.append(`sorts[${filterParams.columnKey}]`, order)
      }
      urlSearchString.append('page', this.blockedUsers.pagination.current_page)
      return http.get(`/api/users/blocked?${urlSearchString.toString()}`)
        .then((response) => {
          this.blockedUsers.users = response.data.data
          this.blockedUsers.pagination = response.data.pagination
        }).finally(() => {
          this.blockedUsers.loading = false
        })
    },

    getUser(id) {
      return http.get(`/api/users/${id}`).then((response) => {
        this.user = response.data
        this.user.company = response.data.company?.name
      })
    },
    async update(id, data) {
      const formData = new FormData()
      formData.append('first_name', data.first_name ?? '')
      formData.append('last_name', data.last_name ?? '')
      formData.append('email', data.email ?? '')
      formData.append('phone_number', data.phone_number ?? '')
      formData.append('company_name', data.company_name ?? '')
      formData.append('language', data.language ?? '')
      formData.append('starting_place', data.starting_place ?? '')

      formData.append('gender', data.gender ?? '')
      if (data.profile_picture instanceof File || data.profile_picture instanceof Blob) {
        formData.append(
          'profile_picture',
          data.profile_picture,
        )
      }
      if (data.profile_picture === null) {
        formData.append(
          'delete_profile_picture',
          1,
        )
      }
      data.roles.forEach((role) => {
        formData.append('roles[]', role)
      })

      // for (const pair of formData.entries())
      //   console.log(`${pair[0]}, ${pair[1]}`)

      return http.post(`/api/users/${id}`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }).then(() => {
        if (data.skills) {
          return http.post(`/api/users/${id}/skills`, {
            skills: data.skills.map((item) => {
              return {
                skill_id: item.id,
                level: item.level,
              }
            }),
          })
        }
      })
    },
    updateUserState(data, id) {
      const request = {
        state: data,
      }
      return http.patch(`/api/users/${id}/state`, request)
    },
    getSkillIndex() {
      this.skillsLoading = true
      return http.get('/api/skills').then((response) => {
        this.skills = response.data
      }).finally(() => {
        this.skillsLoading = false
      })
    },
    getStartingPlaces() {
      this.startingPlacesLoading = true
      return http.get('/api/users/starting-places').then((response) => {
        const newList = []
        // console.log('response', response.data)
        for (const [key, value] of Object.entries(response.data.data))
          newList.push(value)

        this.startingPlaces = newList
      }).finally(() => {
        this.startingPlacesLoading = false
      })
    },
    getUsers() {
      this.getActiveUsers()
      this.getBlockedUsers()
      this.getPendingUsers()
    },
  },
  getters: {
    // userQueryString: (state) => {
    //   state.userQuery.sorts.push('first_name')
    //   const query = _.omitBy(_.cloneDeep(state.userQuery), _.isEmpty)
    //   return query
    // },
  },

})
