import { createI18n } from 'vue-i18n'

// import nl from './locales/nl.json'
// import en from './locales/en.json'
// import de from './locales/de.json'
// import fr from './locales/fr.json'
import messages from '@intlify/unplugin-vue-i18n/messages'

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'nl',
  fallbackLocale: 'nl',
  messages,
})
