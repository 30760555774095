<script setup>
import { useAppSettingsStore } from '../../stores/appSettings'

const settingsStore = useAppSettingsStore()
</script>

<template>
  <div
    class="transition filter absolute inset-0"
    :class="{ 'blur-sm xl:filter-none': settingsStore.responsiveWarning }"
  >
    <slot />
  </div>
  <div
    :class="{ 'opacity-0 pointer-events-none': !settingsStore.responsiveWarning }"
    class="z-99999 absolute inset-0 pointer-event-auto grid place-items-center transition bg-white bg-opacity-75 xl:(opacity-0 pointer-events-none)"
  >
    <div class="flex flex-col items-center justify-center m-5 p-4 md:p-8 rounded-lg bg-white border shadow-lg border-opacity-10">
      <div class=" flex items-center gap-4 ">
        <!-- <img
          src="../../assets/logo/robojob-logo-small.svg"
          alt="RoboJob"
          class="h-20 md:h-30 transition-all"
          draggable="false"
        >
        <IcRoundArrowRightAlt class="md:w-30 md:h-30 transition-all w-20 h-20 flex-shrink-0" /> -->
        <div class=" bg-primary bg-opacity-60 rounded-full border-4 border-white border-opacity-50">
          <MdiMonitorScreenshot class="text-white md:h-20 md:w-20 h-12 w-12 transition-all md:m-6 m-4" />
        </div>
      </div>
      <h1 class="text-center mt-6 text-lg font-bold max-w-md">
        {{ $t('responsive.responsive_warning') }}
      </h1>
      <p class=" text-center mt-2 max-w-md">
        {{ $t('responsive.responsive_warning_body') }}
      </p>
      <n-button
        size="small"
        class="mt-6"
        secondary
        type="error"
        @click="settingsStore.responsiveWarning = false"
      >
        {{ $t('responsive.responsive_warning_button') }}
      </n-button>
    </div>
  </div>
</template>
