import { createRouter, createWebHistory } from 'vue-router'

// import BaseLayout from '@/views/BaseLayout.vue'

import { isEqual } from 'lodash'
import { useAppSettingsStore } from './stores/appSettings'
import { usePermissionsStore } from './stores/permissions'
import { useAuthStore } from '@/stores/auth'
import i18n from '@/i18n'

// const DYNAMIC_IMPORT_ERROR = 'router message Failed to fetch dynamically imported module'

// import LoginView from '@/views/Login.vue'
// import Error404 from '@/views/Error404.vue'

// class DynamicImportError extends Error {
//   constructor(message) {
//     super(message)
//     this.name = 'DynamicImportError'
//   }
// }

// const dynamicImport = (component) => {
//   //  window.$loadingBar.start()
//   return new Promise((resolve, reject) => {
//     component.then((module) => {
//       resolve(module)
//     })
//       .catch((err) => {
//         reject(new DynamicImportError(err.message))
//       })
//   })
// }

const LoginView = () => import('@/views/auth/Login.vue')
const SetPasswordView = () => import('@/views/auth/SetPassword.vue')
const Error404 = () => import('@/views/Error404.vue')
const BaseLayout = () => import('@/views/BaseLayout.vue')

const IndexDossier = () => import('@/views/dossiers/IndexDossiers.vue')
const DetailDossiers = () => import('@/views/dossiers/detailViews/DetailDossier.vue')
const OrderPhase = () => import('@/views/dossiers/detailViews/pages/OrderPhase.vue')
const OrderDocuments = () => import('@/views/dossiers/detailViews/pages/OrderDocuments.vue')
const OrderDocumentsCategoryIndex = () => import('@/views/dossiers/detailViews/pages/OrderDocumentsCategoryIndex.vue')
const OrderDocumentsCategoryDetail = () => import('@/views/dossiers/detailViews/pages/OrderDocumentsCategoryDetail.vue')

const Request = () => import('@/views/dossiers/request/Request.vue')

const AddOrderTemplate = () => import('@/views/templates/orderTemplates/Create.vue')

const IndexUsers = () => import('@/views/users/IndexUsers.vue')
// const IndexProducts = () => import('@/views/products/IndexProducts.vue')
// const DetailProducts = () => import('@/views/products/DetailProducts.vue')
const IndexTemplates = () => import('@/views/templates/IndexTemplates.vue')
const DetailUsers = () => import('@/views/users/DetailUser.vue')
const Planning = () => import('@/views/planning/Planning.vue')
// const DevPage = () => import('@/views/dev/DevPage.vue')

const ADCallbackView = () => import('@/views/auth/ADCallback.vue')
const SharepointCallbackView = () => import('@/views/auth/SharepointCallback.vue')

const TemplateEditor = () => import('@/views/templates/checklistTemplates/TemplateEditor.vue')
const TemplateStep1 = () => import('@/views/templates/checklistTemplates/TemplateStep1.vue')
const TemplateStep2 = () => import('@/views/templates/checklistTemplates/TemplateStep2.vue')
const TemplateStep3 = () => import('@/views/templates/checklistTemplates/TemplateStep3.vue')

const { t } = i18n.global

// !! Als je de route paden veranderd, please ook dan in baseLayout de keys van het menu aanpassen, dezen worden
// !! gebruikt om de menuitems als 'actief' te markeren.

const routes = [
  {
    path: '/',
    name: 'base',
    component: BaseLayout,
    redirect: { name: 'index-dossiers' },
    beforeEnter: async (to, from) => {
      const permissionsStore = usePermissionsStore()
      await Promise.all([
        permissionsStore.getSidebarPermissions(),
        permissionsStore.getIndexPermissions(),
      ])
    },
    meta: {
      requiresAuth: true,
      // child routes who don't redefine the requiresAuth meta tag will also get this meta tag.
    },
    children: [
      {
        path: 'dossiers',
        name: 'index-dossiers',
        component: IndexDossier,
        beforeEnter: async (to, from) => {
          const permissionsStore = usePermissionsStore()
          await permissionsStore.getOrderPermissions()
        },
        children: [],
      },
      {
        path: 'dossiers/:id',
        name: 'detail-dossiers',
        component: DetailDossiers,
        children: [
          {
            path: 'phase/:phaseId',
            name: 'order-phase',
            component: OrderPhase,
          },
          {
            path: 'documents',
            name: 'order-documents',
            component: OrderDocuments,
            children: [
              {
                path: '',
                name: 'order-documents-category-index',
                component: OrderDocumentsCategoryIndex, 
              },
              {
                path: ':categoryKey',
                name: 'order-documents-category-detail',
                component: OrderDocumentsCategoryDetail,
              },
            ]
          },
        ],
      },
      {
        path: 'dossiers/:dossierid/phase/:phaseid/request/:requestid',
        name: 'request',
        component: Request,
      },

      {
        path: 'request/addRequest',
        name: 'add-request',
        component: 'addRequest',

      },
      {
        path: 'users',
        name: 'index-users',
        component: IndexUsers,
        children: [
          {
            path: ':id',
            name: 'detail-users',
            component: DetailUsers,
          },
        ],
      },
      {
        path: 'planning',
        name: 'planner',
        component: Planning,
        beforeEnter: async () => {
          const permissionsStore = usePermissionsStore()
          await permissionsStore.getPlannerPermissions()
        },
      },

      // {
      //   path: 'products',
      //   name: 'index-products',
      //   component: IndexProducts,
      // },
      // {
      //   path: 'products/:id',
      //   name: 'detail-products',
      //   component: DetailProducts,
      // },
      {
        path: 'templates',
        name: 'index-templates',
        component: IndexTemplates,
      },
      {
        path: 'templates/orders',
        name: 'add-order-template',
        component: AddOrderTemplate,
      },
      {
        path: 'templates/orders/:id',
        name: 'edit-order-template',
        component: AddOrderTemplate,
      },

      {
        path: 'templates/checklists',
        name: 'templates-parent',
        redirect: { name: 'new-template' },
        component: TemplateEditor,
        children: [
          {
            path: 'new',
            name: 'new-template',
            component: TemplateStep1,
          },
          {
            path: ':id',
            name: 'edit-template',
            redirect: to => ({ name: 'edit-template-step-1', params: { id: to.params.id } }),
          },
          {
            path: ':id/step-1',
            name: 'edit-template-step-1',
            component: TemplateStep1,
          },
          {
            path: ':id/step-2',
            name: 'edit-template-step-2',
            component: TemplateStep2,
          },
          {
            path: ':id/step-3',
            name: 'edit-template-step-3',
            component: TemplateStep3,
          },

        ],
      },

    ],
  },

  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/reset_password/:token',
    name: 'reset-password',
    component: SetPasswordView,
    meta: {
      requiresAuth: undefined,
    },
  },

  {
    path: '/accept/invite/:token',
    name: 'accept-invite',
    component: SetPasswordView,
    meta: {
      requiresAuth: undefined,
    },
  },

  {
    path: '/api/callback/ad',
    name: 'api-callback-ad',
    component: ADCallbackView,
    meta: {
      requiresAuth: undefined,
    },
  },
  {
    path: '/api/callback/sharepoint',
    name: 'api-callback-sharepoint',
    component: SharepointCallbackView,
    meta: {
      requiresAuth: undefined,
    },
  },

  {
    path: '/:pathMatch(.*)*',
    name: 'error-404',
    component: Error404,
    meta: {
      requiresAuth: undefined,
    },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition)
      return savedPosition

    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
        // , offset: { x: 0, y: 10 }
      }
    }
    return { top: 0 }
  },
})

export default router

router.beforeEach(async (to, from) => {
  const authStore = useAuthStore()
  const appSettings = useAppSettingsStore()

  // Show an error message if the url parameter 'error_msg' is set
  if (to.query.error_msg) {
    console.error(`URLPARAMS ERROR MESSAGE: ${to.query.error_msg}`)
    const message = to.query.error_msg
    window.$message.error(message, {
      closable: true,
      duration: 30000,
      keepAliveOnHover: true,
    })

    const query = Object.assign({}, to.query)
    delete query.error_msg
    return router.replace({ query })
  }

  if (!(from.name === to.name && isEqual(from.params, to.params)))
    window.$loadingBar.start()

  let userHasTokens = false

  if (authStore.hasTokens) {
    try {
      if (!authStore.user)
        await authStore.me()
      userHasTokens = true
    }
    catch (error) {
      authStore.clearTokens()
    }
  }

  // A logged in user shouldn't be able to view unauthenticated pages

  if (to.meta.requiresAuth === false && userHasTokens) {
    // console.info('USER IS LOGGED IN, TRYING TO VIEW UNAUTH PAGE')
    return router.push({ name: 'base' })
  }

  // A user should log in before accessing a page that requires auth
  if (to.meta.requiresAuth === true) {
    // console.info('USER MUST BE LOGGED IN')
    if (!userHasTokens) {
      // console.info('USER IS NOT LOGGED IN')

      if (to.name === 'base' || to.name === 'index-dossiers') {
        // console.info(
        //   'USER HAS NO TOKENS, REDIRECT TO LOGIN (NO REDIRECT QUERY)',
        // )
        if (from.name === 'login')
          return false
        return router.push({ name: 'login' })
      }
      else {
        window.$message.warning(t('routing.notLoggedIn'))
        // console.info(
        //   'USER HAS NO TOKENS, REDIRECT TO LOGIN (WITH REDIRECT QUERY)',
        // )
        if (from.name === 'login')
          return false
        appSettings.redirectUrl = to.fullPath
        return router.push({
          name: 'login',
        })
      }
    }
  }
})

router.afterEach(() => {
  window.$loadingBar.finish()
})

router.onError((error) => {
  window.$loadingBar.error()
  throw error
})
