import { defineStore } from 'pinia'

import http from '../http'

export const usePermissionsStore = defineStore('permissions', {
  state: () => {
    return {
      checklistPermissions: {
        
      },
      orderPermissions: {
        create_orders: null,
        activate_orders: null,
        sync_ridder: null,
        sync_ridder_again: null,
      },
      userPermissions: {
        invite_users: false,
        manage_skills: false,
        role_management: false,
      },
      sidebarPermissions: {
        see_agendas: null,
        see_orders: null,
        see_products: null,
        see_templates: null,
        see_users: null,
      },
      indexPermissions: {
        see_topbar: null,
        see_contact_form: null,
      },
      plannerPermissions: {
        create_agenda_item: null,
        create_global_agenda_item: null,
        see_filter_list: null,
      },
    }
  },
  actions: {
    getSidebarPermissions() {
      return http.options('api/sidebar').then((response) => {
        this.sidebarPermissions = response.data[0]
      })
    },
    getIndexPermissions() {
      return http.options('api/index-actions').then((response) => {
        this.indexPermissions = response.data[0]
      })
    },
    getOrderPermissions() {
      return http.options('/api/orders').then((response) => {
        this.orderPermissions = response.data[0]
      })
    },
    getUsersPermissions() {
      // console.log('get user permissions')
      return http.options('/api/users').then((response) => {
        this.userPermissions = response.data[0]
      })
    },
    getPlannerPermissions() {
      return http.options('/api/agendas').then((response) => {
        this.plannerPermissions = response.data[0]
      })
    },
  },
})
