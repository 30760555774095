<script setup>
import {
  NConfigProvider,
  NDialogProvider,
  NGlobalStyle,
  NLoadingBarProvider,
  NMessageProvider,
  NThemeEditor,
  deDE,
  enUS,
  frFR,
  nlNL,
} from 'naive-ui'
import { useI18n } from 'vue-i18n'
import { useHead } from '@vueuse/head'
import { computed } from 'vue'
import GlobalUtilProvider from './components/utils/GlobalUtilProvider.vue'
import customTheme from './customTheme.json'
import { useAppSettingsStore } from './stores/appSettings'
import { useAuthStore } from './stores/auth'
import naiveI18nDate from './naivelocales/naive-i18n-dates'
import { usePageTitle } from './composables/pageTitle'
import { setLanguage as setHttpHeaderLanguage } from '@/http'

const i18n = useI18n()
// const t = i18n.t

const appSettings = useAppSettingsStore()

useHead({
  htmlAttrs: {
    lang: computed(() => i18n.locale.value),
  },
})

const authStore = useAuthStore()

function setupLanguages() {
  if (authStore.user?.language) {
    i18n.locale.value = authStore.user.language
    setHttpHeaderLanguage(authStore.user.language)
    return
  }

  if (appSettings.appLanguage) {
    if (i18n.availableLocales.includes(appSettings.appLanguage)) {
      i18n.locale.value = appSettings.appLanguage
      setHttpHeaderLanguage(appSettings.appLanguage)
      return
    }
  }
  for (const lang of navigator.languages) {
    for (const appLang of i18n.availableLocales) {
      if (lang.includes(appLang)) {
        i18n.locale.value = appLang
        setHttpHeaderLanguage(appLang)
        return
      }
    }
  }
  i18n.locale = 'nl'
  setHttpHeaderLanguage('nl')
}

setupLanguages()

watch(() => i18n.locale.value, (newLocale) => {
  // console.log('WATCH: Setting language to', newLocale)
  appSettings.appLanguage = newLocale
  setHttpHeaderLanguage(newLocale)
  if (authStore.user && authStore.user.language !== newLocale)
  // do the call to the backend to update the user language
    authStore.setCurrentUserLanguage(newLocale)
})

watch(() => authStore.user?.language, (userLocale, oldUserLocale) => {
  if (userLocale === oldUserLocale)
    return
  i18n.locale.value = userLocale
  setHttpHeaderLanguage(userLocale)
})

usePageTitle(null)

// NaiveUI internationalization
const naiveI18n = computed(() => {
  switch (i18n.locale.value) {
    case 'nl':
      return nlNL
    case 'fr':
      return frFR
    case 'de':
      return deDE
    case 'en':
      return enUS
    default:
      return enUS
  }
})

const isDev = import.meta.env.DEV
</script>

<template>
  <NConfigProvider
    :locale="naiveI18n"
    :date-locale="naiveI18nDate"
    :theme-overrides="customTheme"
    inline-theme-disabled
  >
    <component :is="isDev ? NThemeEditor : 'section'">
      <NLoadingBarProvider>
        <NMessageProvider>
          <NDialogProvider>
            <ResponsiveWarning>
              <router-view />
            </ResponsiveWarning>
          </NDialogProvider>
          <GlobalUtilProvider />
        </NMessageProvider>
      </NLoadingBarProvider>
      <NGlobalStyle />
    </component>
  </NConfigProvider>
</template>

<style lang="scss">
body {
  @apply font-sans;
}
</style>
