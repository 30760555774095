import enGB from 'date-fns/locale/en-GB'
import nlBE from 'date-fns/locale/nl-BE'
import fr from 'date-fns/locale/fr'
import de from 'date-fns/locale/de'
import { computed } from 'vue'
import i18n from '../i18n'

function getCurrentDateLocale() {
  switch (i18n.global.locale.value) {
    case 'nl':
      return nlBE
    case 'en':
      return enGB
    case 'fr':
      return fr
    case 'de':
      return de
  }
}

const naiveI18nDate = computed(() => {
  return {
    name: 'i18n-dates',
    locale: getCurrentDateLocale(),
  }
})

export default naiveI18nDate
